<template>
  <b-container fluid>
    <b-row class="justify-content-md-center auth-wrapper px-2">
      <b-col md="8">

        <!-- Login v1 -->
        <b-overlay :show="loader" spinner-variant="primary" spinner-type="grow" spinner-lg rounded="sm">
          <b-card class="mb-0 my-5">
            <form-wizard color="#7367F0" title="Formulaire d'inscription"
              subtitle="Rejoignez nous pour profitez des opportunités que vous offre la plateforme"
              finish-button-text="S'inscrire" back-button-text="Précédent" next-button-text="Suivant" button-size="sm"
              class="steps-transparent mb-3 font-size" justified @on-complete="storeUser()">
              <tab-content :before-change="validationIsConditionForm">
                <validation-observer ref="accountConditionRules" tag="form">
                  <b-row>
                    <b-col md="12">
                      <b-alert variant="dark" show>
                        <h4 class="alert-heading">
                          Conditions d'utilisation
                        </h4>
                        <div class="alert-body">
                          Voici les conditions d'utilisation de la plateforme bourse de frêt:
                          <ul>
                            <li>Les conditions générales d’utilisation ne sont pas un abonnement obligatoire. Il s’agit
                              d’un abonnement optionnel qu’il est possible de soumettre à l’utilisateur d’un service pour
                              en encadrer l’utilisation.</li>
                            <li>C'est un abonnement contractuel. On parle de contrat d’adhésion par opposition au contrat
                              négocié. L’utilisateur du service choisit de les accepter ou de les refuser mais ne négocie
                              pas leur contenu avec l’exploitant du service.</li>
                            <li>Sur internet, les conditions générales d’utilisation peuvent être utilisées sur les sites
                              marchands comme sur les sites non marchands, leur objet étant d’encadrer l’utilisation du
                              site. Elles ne sont pas une obligation légale. En revanche, les conditions générales de
                              vente ne trouvent d’intérêt que sur les sites marchands, leur objet étant d’encadrer une
                              relation commerciale. Elles constituent une obligation légale sur tous les sites marchands.
                            </li>
                            <li>Les CGU sont souvent accusées d'être écrites dans un langage juridique peu compréhensible
                              pour un utilisateur lambda ainsi que dans une taille de police trop petite. Ces contrats
                              sont généralement très long et peu d'internautes les lisent en entier. Plusieurs initiatives
                              ont néanmoins vu le jour pour clarifier les dispositions que l'utilisateur accepte</li>
                          </ul>
                        </div>
                      </b-alert>
                      <validation-provider #default="{ errors }" name="condition d'utilisation "
                        :rules="{ required: { allowFalse: false } }">
                        <!--/ collapse -->
                        <b-form-checkbox v-model="form.is_condition" :state="errors.length > 0 ? false : null"
                          class="custom-control-primary mt-2">
                          Lu et accepté
                        </b-form-checkbox>

                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-col>
                    <b-col md="12">
                      <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="danger" class="float-right"
                        onclick="history.back()">
                        <feather-icon icon="ArrowLeftCircleIcon" />
                        Annuler
                      </b-button>
                    </b-col>
                  </b-row>
                </validation-observer>
              </tab-content>

              <tab-content :before-change="validationForm">
                <validation-observer ref="accountRules" tag="form"><b-row>
                    <b-col md="12" class="mb-2">
                      <h5 class="mb-0">
                        Détails relatifs a la structure
                      </h5>
                      <small class="text-muted"> Détails </small>
                    </b-col>

                    <b-col md="6">
                      <b-form-group label="Nom de la structure" label-for="O">
                        <validation-provider #default="{ errors }" name="nom de la structure" rules="required">
                          <b-form-input v-model="form.O" size="sm" :state="errors.length > 0 ? false : null"
                            placeholder="Nom de la structure" />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group label="Nom de la branche (Marketing, Ventes, etc...)" label-for="OU">
                        <validation-provider #default="{ errors }" name="nom de la branche" rules="required">
                          <b-form-input v-model="form.OU" size="sm" :state="errors.length > 0 ? false : null"
                            placeholder="Nom de la branche" />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group label="Nom du certificat" label-for="CN">
                        <validation-provider #default="{ errors }" name="nom du certificat" rules="required">
                          <b-form-input v-model="form.CN" size="sm" :state="errors.length > 0 ? false : null"
                            placeholder="Nom du certificat" />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group label="Département du siege social" label-for="departement">
                        <validation-provider #default="{ errors }" name="département" rules="required">
                          <v-select id="role" v-model="form.S" label="nom" :state="errors.length > 0 ? false : null"
                            :options="roles" placeholder="Choisir un departement" :reduce="(roles) => roles"
                            class="select-size-sm" />
                          <!-- <b-form-input
                          v-model="form.S"
                          size="sm"
                          :state="errors.length > 0 ? false : null"
                          placeholder="Département"
                        /> -->
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group label="Pays" label-for="pays">
                        <validation-provider #default="{ errors }" name="pays">
                          <b-form-input v-model="form.C" size="sm" type="text" :state="errors.length > 0 ? false : null"
                            placeholder="Pays" disabled />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group label="Ville du siege social" label-for="ville">
                        <validation-provider #default="{ errors }" name="ville" rules="required">
                          <v-select id="role" v-model="form.L" :state="errors.length > 0 ? false : null"
                            :options="form.S.communes" placeholder="Choisir une ville" :reduce="(roles) => roles"
                            class="select-size-sm" />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="12">
                      <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="danger" class="float-right"
                        onclick="history.back()">
                        <feather-icon icon="ArrowLeftCircleIcon" />
                        Annuler
                      </b-button>
                    </b-col>
                  </b-row>
                </validation-observer>
              </tab-content>

              <tab-content :before-change="validationPersonnalForm">
                <validation-observer ref="accountPersonnalRules" tag="form">
                  <b-row>
                    <b-col md="12" class="mb-2">
                      <h5 class="mb-0">
                        Détails de l'administrateur
                      </h5>
                      <small class="text-muted"> Détails </small>
                    </b-col>
                    <b-col md="6">
                      <b-form-group label="Nom d'utilisateur" label-for="username">
                        <validation-provider #default="{ errors }" name="nom d'utilisateur" rules="required">
                          <b-form-input v-model="form.username" size="sm" :state="errors.length > 0 ? false : null"
                            placeholder="Nom d'utilisateur" />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group label="Nom" label-for="lastname">
                        <validation-provider #default="{ errors }" name="nom" rules="required">
                          <b-form-input v-model="form.lastname" size="sm" :state="errors.length > 0 ? false : null"
                            placeholder="Nom" />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group label="Prénom" label-for="firstname">
                        <validation-provider #default="{ errors }" name="prénom" rules="required">
                          <b-form-input v-model="form.firstname" size="sm" :state="errors.length > 0 ? false : null"
                            placeholder="Prénom" />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group label="Email" label-for="email">
                        <validation-provider #default="{ errors }" name="email" rules="required|email">
                          <b-form-input v-model="form.email" size="sm" type="email"
                            :state="errors.length > 0 ? false : null" placeholder="Email" />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group label="Adresse" label-for="adresse">
                        <validation-provider #default="{ errors }" name="adresse" rules="required">
                          <b-form-input v-model="form.adresse" size="sm" :state="errors.length > 0 ? false : null"
                            placeholder="Adresse" />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group label="Téléphone" label-for="user_phone">
                        <validation-provider #default="{ errors }" name="téléphone" rules="required" type="tel">
                          <VuePhoneNumberInput v-model="form.telephonefull" default-country-code="BJ" show-code-on-list
                            size="sm" :translations="configPhoneNumber" @update="telephonefull = $event" />

                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="6">

                      <b-form-group label="Mot de passe" label-for="a-password">
                        <validation-provider #default="{ errors }" name="mot de passe" vid="Password"
                          rules="required|min:8|password">
                          <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid' : null">
                            <b-form-input id="a-password" v-model="form.password" :type="passwordFieldType"
                              class="form-control-merge" size="sm" :state="errors.length > 0 ? false : null"
                              placeholder="Mot de passe" />

                            <b-input-group-append is-text>
                              <feather-icon class="cursor-pointer" :icon="passwordToggleIcon"
                                @click="togglePasswordVisibility" />
                            </b-input-group-append>
                          </b-input-group>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group label="Confirmer mot de passe" label-for="ac-password">
                        <validation-provider #default="{ errors }" name="mot de passe"
                          rules="required|confirmed:Password">

                          <b-form-input id="ac-password" v-model="form.password_confirmation"
                            :state="errors.length > 0 ? false : null" type="password" size="sm"
                            placeholder="Confirmer mot de passe" />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="12">
                      <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="danger" class="float-right"
                        onclick="history.back()">
                        <feather-icon icon="ArrowLeftCircleIcon" />
                        Annuler
                      </b-button>
                    </b-col>
                  </b-row>
                </validation-observer>
              </tab-content>

              <tab-content :before-change="validationFilesForm">
                <b-row class="justify-content-md-center">
                  <b-col md="12" class="">
                    <div class="row gy-4" data-aos="fade-left">

                      <div v-for="abonnement in abonnements" :key="abonnement.libelle" class="col-lg-4 col-md-6"
                        data-aos="zoom-in" data-aos-delay="100">
                        <div class="box">
                          <h3 style="color: #07d5c0;">
                            {{ abonnement.libelle }}
                          </h3>
                          <div class="price">
                            {{ abonnement.prix }}<sup>F CFA</sup> pour <span> {{ abonnement.nombre }} cev</span>
                          </div>
                          <img src="assets/img/pricing-free.png" class="img-fluid" alt="">
                          <ul>
                            <li>{{ abonnement.description }}</li>
                          </ul>
                          <a href="#" class="btn btn-primary w-100" @click="open(abonnement.prix, abonnement.id)">Payer
                            maintenant</a>
                        </div>
                      </div>

                    </div>
                  </b-col>
                  <b-col md="12">
                    <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="danger" class="float-right"
                      onclick="history.back()">
                      <feather-icon icon="ArrowLeftCircleIcon" />
                      Annuler
                    </b-button>
                  </b-col>

                </b-row>
              </tab-content>
            </form-wizard>

          </b-card>
        </b-overlay>
        <!-- /Login v1 -->
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'

import {
  ref, reactive, watch, onMounted,
} from '@vue/composition-api'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required, email, confirmed, password, image, ext, mimes,
} from '@validations'
// eslint-disable-next-line import/no-cycle
import useStructureRegister from '@/services/authentification/registerStructureService'
// eslint-disable-next-line import/no-cycle
import useImageUpload from '@/services/upload/useImageUpload'
import VuePhoneNumberInput from 'vue-phone-number-input'
// eslint-disable-next-line import/no-extraneous-dependencies
import { French } from 'flatpickr/dist/l10n/fr'

import { togglePasswordVisibility } from '@core/mixins/ui/forms'

import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BContainer,
  BInputGroup,
  BOverlay,

  BInputGroupAppend,
  BCard,
  BFormCheckbox,
  BButton, VBModal, BAlert,

} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import {
  openKkiapayWidget,
  addKkiapayListener,
  removeKkiapayListener,
} from 'kkiapay'
// eslint-disable-next-line import/no-cycle
import useAbonnements from '@/services/admin/abonnementService'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BInputGroupAppend,
    BInputGroup,
    BOverlay,

    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BContainer,
    BAlert,
    BFormCheckbox,
    BButton,
    vSelect,
    VuePhoneNumberInput,
    // eslint-disable-next-line vue/no-unused-components

  },

  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  mixins: [togglePasswordVisibility],

  data() {
    return {
      required,
      password,
      email,
      confirmed,
      image,
      ext,
      mimes,
      isOpen: true
    }
  },
  setup() {
    const { handleRegister, loader,errors } = useStructureRegister()
    const {
      getAbonnements, getClientAccess, checkAbonnement, abonnements, getAbonnement, abonnement, abonnementProcess,
    } = useAbonnements()
    const {
      imageUrl, imageFile, handleImageSelected, uploadFile, fileName, deleteFile,
    } = useImageUpload()

    onMounted(async () => {
      await getAbonnements()
      console.log(abonnements)
    })
    const submittedNames = []
    const fields = reactive([
      { key: 'name_abonnement', label: 'Type de abonnement' },
      { key: 'numero_de_la_piece', label: 'Numéro de la pièce' },
      { key: 'date_expiration', label: 'Date d\'expiration' },
      { key: 'actions' },
    ])

    const items = reactive([])
    const piece = ref({})
    const clearPiece = () => {
      piece.value = {}
      imageUrl.value = ''
      fileName.value = ''
    }
    async function queuePurchase() {
      const data = new FormData()
      data.append('fichier', imageFile.value)
      await uploadFile(data)
      await getAbonnement(piece.value.type_abonnement)
      piece.value.type_abonnement = abonnement.value.id
      piece.value.name_abonnement = abonnement.value.name
      piece.value.fileName = fileName.value
      piece.value.imageUrl = imageUrl.value
      items.push(piece.value)
      clearPiece()
    }

    const roles = [
      {
        nom: 'Alibori',
        communes: [
          'Banikoara',
          'Bokoumbé',
          'Gogounou',
          'Kérou',
          'Kouandé',
          'Matéri',
          'Natitingou',
          'Péhunco',
          'Tanguiéta',
        ],
      },
      {
        nom: 'Atlantique',
        communes: [
          'Allada',
          'Anfoin',
          'Azohouè-Kpèdo',
          'Bopa',
          'Cotonou',
          'Dogbo',
          'Grand-Popo',
          'Kétou',
          'Kloto',
          'Lokossa',
          'Sèmè-Kpodji',
          'Toffo',
        ],
      },
      {
        nom: 'Borgou',
        communes: [
          'Borgou',
          'Djougou',
          "N'Dali",
          'Parakou',
          'Sinendé',
          'Tanguiéta',
        ],
      },
      {
        nom: 'Collines',
        communes: [
          'Bafilo',
          'Dassa-Zoumè',
          'Djougou',
          'Kérou',
          'Natitingou',
          'Savè',
          'Toucountouna',
        ],
      },
      {
        nom: 'Couffo',
        communes: [
          'Bohicon',
          'Djougou',
          'Kpétou',
          'Malanville',
          "N'Dali",
          'Ségbana',
        ],
      },
      {
        nom: 'Donga',
        communes: [
          'Djougou',
          'Kérou',
          'Natitingou',
          'Péhunco',
          'Tanguiéta',
        ],
      },
      {
        nom: 'Littoral',
        communes: [
          'Abomey-Calavi',
          'Cotonou',
          'Djougou',
          'Kétou',
          'Kloto',
          'Lokossa',
          'Sèmè-Kpodji',
          'Toffo',
        ],
      },
      {
        nom: 'Mono',
        communes: [
          'Athiémé',
          'Bopa',
          'Cotonou',
          'Djougou',
          'Grand-Popo',
          'Lokossa',
          'Sèmè-Kpodji',
          'Toffo',
        ],
      },
      {
        nom: 'Ouémé',
        communes: [
          'Adjarra',
          'Adjohoun',
          'Akpro-Missérété',
          'Aguégués',
          'Avrankou',
          'Bété',
          'Bohicon',
          'Bopa',
          'Cotonou',
          'Djougou',
          'Kétou',
          'Kloto',
          'Lokossa',
          'Sèmè-Kpodji',
          'Toffo',
        ],
      },
      {
        nom: 'Plateau',
        communes: [
          'Adja-Ouèrè',
          'Ifangni',
          'Kétou',
          'Pobè',
          'Sakété',
        ],
      },
      {
        nom: 'Zou',
        communes: [
          'Abomey-Calavi',
          'Djougou',
          'Kétou',
          'Kloto',
          'Lokossa',
          'Sèmè-Kpodji',
          'Toffo',
        ],
      },
    ]
    const getImage = data => {
      console.clear()
      imageUrl.value = data.item.imageUrl
    }

    const removeItem = async data => {
      console.clear()
      await deleteFile(data.item.fileName)
      const indexOfObject = items.findIndex(object => object.fileName === data.item.fileName)
      items.splice(indexOfObject, 1)
    }

    const form = reactive({
      role: 'ROLE_STRUCTURE',
      is_condition: '',
      username: '',
      lastname: '',
      firstname: '',
      email: '',
      adresse: '',
      telephone: '',
      telephonefull: '',
      password: '',
      password_confirmation: '',
      abonnement_id: '',
      CN: '',
      O: '',
      OU: '',
      L: '',
      S: '',
      C: 'BJ',

      selected: '',
      fichiers: items,
    })
    const telephonefull = ref('')
    // eslint-disable-next-line no-unused-vars
    watch(() => (form.telephonefull), value => {
      form.telephone = ''
      form.telephone = telephonefull.value.formattedNumber
      console.clear()
    })
    // S'Enregistrer
    const storeUser = async () => {
      await handleRegister({ ...form })
      // if (errors.value === "") {
      //   this.success()
      // }
    }
    const config = {
      wrap: true, // set wrap to true only when using 'input-group'
      altFormat: 'j M  Y',
      altInput: true,
      dateFormat: 'Y-m-d',
      locale: French, // locale for this instance only
    }

    return {
      roles,
      telephonefull,
      abonnements,
      config,
      checkAbonnement,
      form,
      submittedNames,
      items,
      fields,
      storeUser,
      queuePurchase,
      getImage,
      removeItem,
      getClientAccess,
      piece,
      imageUrl,
      errors,
      clearPiece,
      loader,
      abonnementProcess,
      handleImageSelected,

    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    isModalVisible() {
      return this.isOpen;
    }
  },
  mounted() {
    addKkiapayListener('success', this.successHandler)
  },
  beforeDestroy() {
    removeKkiapayListener('success', this.successHandler)
  },
  methods: {
    open(amount, id) {
      this.form.abonnement_id = id
      console.log(this.form)
      openKkiapayWidget({
        amount,
        api_key: '0160bea0d63d11edb475518c9ef1db3a',
        sandbox: true,
        // phone: '97000000',
      })
    },
    successHandler(response) {
      this.storeUser()
      // this.checkAbonnement(response.transactionId)
      console.log(response)
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmitModal()
    },
    formatter(value) {
      return value.toLowerCase()
    },

    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },

    validationIsConditionForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountConditionRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationPersonnalForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountPersonnalRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },

    validationFilesForm() {
      return new Promise((resolve, reject) => {
        if (this.form.fichiers.length >= 2) {
          resolve(true)
        } else {
          reject()
        }
      })
    },
    validationEntrepriseForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountEntrepriseRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    handleSubmitModal() {
      // Exit when the form isn't valid
      this.$refs.accountFilesRules.validate().then(success => {
        if (success) {
          // Push the name to submitted names
          this.queuePurchase(this.piece)
          this.$refs['file-input'].reset()
          // Hide the modal manually
          this.$nextTick(() => {
            this.$refs['my-modal'].toggle('#toggle-btn')
          })
        }
      })
    },
    onToggle() {
      this.isOpen = !this.isOpen;
    },
    success() {
      this.$swal({
        title: 'Bravo!',
        text: 'Vous venez de soummettre votre demande de création de compte! Un mail vous sera envoyé dès que votre compte aurait été créé.',
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },

    // error
    error() {
      this.$swal({
        title: 'Error!',
        text: ' You clicked the button!',
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },

    // warning
    warning() {
      this.$swal({
        title: 'Warning!',
        text: ' You clicked the button!',
        icon: 'warning',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },

    // info
    info() {
      this.$swal({
        title: 'Info!',
        text: 'You clicked the button!',
        icon: 'info',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
  },

}
</script>

<style scoped>
.font-size {
  font-size: 9 px !important;
}

.auth-wrapper {
  background-image: url(https://cdn.pixabay.com/photo/2022/01/30/14/55/wallet-6981329_1280.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  min-height: 100%;
  min-width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  /* background-repeat: no-repeat; */
}
</style>
<style lang="scss" >
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
